// gatsby-browser.js

// Executes when the client application is initialized in the browser
export const onClientEntry = () => {
  console.log("Client entry: Gatsby app has started!");
};

// Executes when the initial page has fully rendered
export const onInitialClientRender = () => {
  console.log("Initial client render completed.");
};

// Called when the user navigates to a new page
export const onRouteUpdate = ({ location, prevLocation }) => {
  console.log("Route updated:", {
    currentPath: location.pathname,
    previousPath: prevLocation ? prevLocation.pathname : null,
  });
};

// Wraps the root element with additional components (e.g., Context Providers)
export const wrapRootElement = ({ element }) => {
  return element; // Return the element unchanged for now
};

// Wraps the page element with additional components (e.g., layout components)
export const wrapPageElement = ({ element, props }) => {
  return element; // Return the element unchanged for now
};
