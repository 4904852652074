exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-[ids]-js": () => import("./../../../src/pages/blog/[ids].js" /* webpackChunkName: "component---src-pages-blog-[ids]-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-terms-and-condition-js": () => import("./../../../src/pages/legal/terms-and-condition.js" /* webpackChunkName: "component---src-pages-legal-terms-and-condition-js" */),
  "component---src-pages-location-[ids]-js": () => import("./../../../src/pages/location/[ids].js" /* webpackChunkName: "component---src-pages-location-[ids]-js" */),
  "component---src-pages-location-index-js": () => import("./../../../src/pages/location/index.js" /* webpackChunkName: "component---src-pages-location-index-js" */),
  "component---src-pages-multiple-index-js": () => import("./../../../src/pages/Multiple/index.js" /* webpackChunkName: "component---src-pages-multiple-index-js" */),
  "component---src-pages-portfolio-[portfolio-inner]-index-js": () => import("./../../../src/pages/portfolio/[portfolio-inner]/index.js" /* webpackChunkName: "component---src-pages-portfolio-[portfolio-inner]-index-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-response-js": () => import("./../../../src/pages/response.js" /* webpackChunkName: "component---src-pages-response-js" */),
  "component---src-pages-services-api-development-index-js": () => import("./../../../src/pages/services/api-development/index.js" /* webpackChunkName: "component---src-pages-services-api-development-index-js" */),
  "component---src-pages-services-artificial-intelligence-index-js": () => import("./../../../src/pages/services/artificial-intelligence/index.js" /* webpackChunkName: "component---src-pages-services-artificial-intelligence-index-js" */),
  "component---src-pages-services-customer-growth-as-service-content-marketing-services-js": () => import("./../../../src/pages/services/customer-growth-as-service/content-marketing-services.js" /* webpackChunkName: "component---src-pages-services-customer-growth-as-service-content-marketing-services-js" */),
  "component---src-pages-services-customer-growth-as-service-ecommerce-solution-services-js": () => import("./../../../src/pages/services/customer-growth-as-service/ecommerce-solution-services.js" /* webpackChunkName: "component---src-pages-services-customer-growth-as-service-ecommerce-solution-services-js" */),
  "component---src-pages-services-customer-growth-as-service-index-js": () => import("./../../../src/pages/services/customer-growth-as-service/index.js" /* webpackChunkName: "component---src-pages-services-customer-growth-as-service-index-js" */),
  "component---src-pages-services-customer-growth-as-service-paid-marketing-services-js": () => import("./../../../src/pages/services/customer-growth-as-service/paid-marketing-services.js" /* webpackChunkName: "component---src-pages-services-customer-growth-as-service-paid-marketing-services-js" */),
  "component---src-pages-services-customer-growth-as-service-seo-services-js": () => import("./../../../src/pages/services/customer-growth-as-service/seo-services.js" /* webpackChunkName: "component---src-pages-services-customer-growth-as-service-seo-services-js" */),
  "component---src-pages-services-customer-growth-as-service-web-development-services-js": () => import("./../../../src/pages/services/customer-growth-as-service/web-development-services.js" /* webpackChunkName: "component---src-pages-services-customer-growth-as-service-web-development-services-js" */),
  "component---src-pages-services-ecommerce-index-js": () => import("./../../../src/pages/services/ecommerce/index.js" /* webpackChunkName: "component---src-pages-services-ecommerce-index-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-mobile-application-development-index-js": () => import("./../../../src/pages/services/mobile-application-development/index.js" /* webpackChunkName: "component---src-pages-services-mobile-application-development-index-js" */),
  "component---src-pages-services-process-optimization-service-index-js": () => import("./../../../src/pages/services/process-optimization-service/index.js" /* webpackChunkName: "component---src-pages-services-process-optimization-service-index-js" */),
  "component---src-pages-services-ui-ux-index-js": () => import("./../../../src/pages/services/ui-ux/index.js" /* webpackChunkName: "component---src-pages-services-ui-ux-index-js" */),
  "component---src-pages-services-web-development-service-index-js": () => import("./../../../src/pages/services/web-development-service/index.js" /* webpackChunkName: "component---src-pages-services-web-development-service-index-js" */)
}

